html {
    box-sizing: border-box;
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

body {
    /* background: #27AE60; */
    font-family: 'Roboto' !important;
    font-style: normal;
    font-size: 12px;
    line-height: 1.2;
    background-color: #FBFBFA !important;
    color: #393017;
}

h1 {
    font-weight: bold;
    font-size: 40px;
}

h3 {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
}

h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

p {
    margin-bottom: 0;
}

.main h1 {
    margin-bottom: 20px;
}

.hide {
    display: none !important;
}

.kirins_red {
    color: #DC3545;
}
.kirins_green {
    color: #27AE60;
}
.kirins_brown {
    color: #393017;
}
.kirins_yellow {
    color: #FFB200;
}

.kirins_bg_red {
    background: #DC3545;
}
.kirins_bg_green {
    background: #27AE60;
}
.kirins_bg_brown {
    background: #393017;
}
.kirins_bg_yellow {
    /* background: rgba(227,216,10,.4); */
    background: #FFB200;
    color: #393017 !important;
}

/* UX/UI */

.myBtn__green {
    background: #27AE60;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    padding: 7px 12px;
    border: none;
}

.myBtn__green-a {
    display: block;
    background: #27AE60;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    padding: 7px 12px;
    border: none;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
}

.myBtn__green-empty {
    background: #fff;
    border: 1px solid #27AE60;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 12px;
    font-size: 14px;
    /* line-height: 24px; */
    color: #27AE60;
}

.myBtn__green-a-empty {
    display: block;
    border: 1px solid #27AE60;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 12px;
    font-size: 14px;
    /* line-height: 24px; */
    color: #27AE60;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
}

.myBtn__green:hover, .myBtn__green-a:hover {
    background: #393017;
    color: #fff;
  }

.myBtn__grey {
    background: #696F6C;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
    padding: 7px 12px;
    border: none;
}

.myBtn__greey:hover, .myBtn__greey-a:hover {
    background: #DFDFDF;
    color: #696F6C;
}

.myBtn__grey-a {
    display: block;
    background: #696F6C;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
    padding: 7px 12px;
    border: none;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
}

.myBtn__green-empty:hover, .myBtn__grey-empty:hover, .myBtn__green-a-empty:hover, .myBtn__grey-a-empty:hover {
    background: #393017;
    color: #fff;
    border: 1px solid #393017;
  }

.myBtn__white {
    background: #fff;
    border-radius: 5px;
    font-size: 14px;
    color: #696F6C;
    padding: 7px 12px;
    border: 1px solid #696F6C;
}

.myBtn__white-a {
    display: block;
    background: #fff;
    border-radius: 5px;
    font-size: 14px;
    color: #696F6C;
    padding: 7px 12px;
    border: 1px solid #696F6C;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
}

.myBtn__red {
    background: #DC3545;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    padding: 7px 12px;
    border: 1px solid #DC3545;
}

.myBtn__green:hover {
    background: #393017;
}

.myBtn__green:disabled, .myBtn__green-a:disabled, .myBtn__green-empty:disabled, .myBtn__green-a-empty:disabled,
.myBtn__greey:disabled, .myBtn__greey-a:disabled, .myBtn__white:disabled, .myBtn__white-a:disabled  {
  cursor: not-allowed;
}

.myBtn__green:disabled {
  color: #fff;
  background: #eee;
}

/* Not Found */

.notFound__img-block {
    display: flex;
    justify-content: center;
}

.notFound__img-block img {
    width: 500px;
    max-width: 100%;
    height: auto;
}

/* end - Not Found */

/* DatePiker - календарь */

.react-datepicker__header--custom>div {
    align-items: center;
      text-align: center;
      display: flex;
      justify-content: center;
  }
  
  .react-datepicker button {
    color: #fff;
    background: #27AE60;
    border: none;
    margin: 0 10px;
    /* padding: 1px 5px; */
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  }
  
  .react-datepicker button:hover {
    background: #393017;
    color: #fff;
  }
  
  .react-datepicker button:disabled {
    background: #eee;
    cursor: not-allowed;
  }
  
  .react-datepicker select {
    background: #FFFFFF;
      border: 1px solid #CED4DA;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      padding: 4px 2px;
  }
  
  .react-datepicker select:hover {
    color: #27AE60;
  }
  
  .react-datepicker__day--selected {
    background-color: #27AE60 !important;
  }
  
  .react-datepicker__input-container>div {
    width: 100%;
  }

  /* YearPicher */
  .react-datepicker__year-wrapper {
    max-width: 206px !important;
  }

  .react-datepicker__year .react-datepicker__year-text {
    font-size: 16px;
    padding: 5px 0px;
   }

   .react-datepicker__year-text--selected,
    .react-datepicker__year-text--keyboard-selected {
        background-color: #27AE60 !important;
    }

    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
        background-color: #393017 !important
    }

.addBtn__logzone {
    margin-bottom: 20px;
}

.addBtn__logzone svg {
    margin-right: 8px;
}

/* end - UX/UI */

/* Header */

header {
    background: #F8F9FA;
    padding: 6px 0 12px 0;
    font-size: 14px;
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999; */
}

.header__wrap {
    display: flex;
    justify-content: space-between;
}

.header__logo img {
    width: 100px;
    height: auto;
}

.header__nav-ul {
    display: flex !important;
    flex-direction: row !important;
}

.header__nav-desktop {
    display: flex;
    flex-direction: row;
}

.header__nav-ul li a {
    display: block;
    padding: 8px 12px !important;
    color: #696F6C;
    border-radius: 4px;
    text-decoration: none;
    margin-right: 4px;
}

.header__nav-ul li a:hover {
    background: #27AE60;
    color: #fff;
}

.header__nav-ul li a>.active {
    background: #27AE60;
}

.header__nav-desktop .header__nav-ul .dropdown-toggle {
    /* margin-bottom: 4px; */
}

.header__nav-desktop .header__nav-ul .dropdown:hover .isurance__nav-ul {
    display: block;
}

#isurance__nav {
    background: #27AE60;
    color: #fff;
}

#isurance__nav:hover::after {
    border-top: 0;
    border-bottom: 0.3em solid;
}

.isurance__nav-ul {
    position: absolute !important;
}

.header__nav-mobile {
    display: none;
}

/* .header__nav-mobile .header__nav-ul .dropdown-toggle {
    margin-bottom: 4px;
} */

.header__nav-mobile .header__nav-ul .dropdown:hover .isurance__nav-ul {
    display: block;
}

.header__nav-mobile .dropdown-menu {
    right: 0;
}

.header__nav-mobile-ul li a {
    display: flex;
    padding: 8px 10px !important;
  }

/* end - Header */

/* Блок профиля в шапке */

.profileBlock>div>svg {
    cursor: pointer;
    color: #27AE60;
    width: 35px;
    height: 35px;
  }
  
  .profileBlock li svg {
    margin-right: 8px;
    color: #393017;
  }
  
  .profileBlock li:last-child, .profileBlock li:last-child svg {
    color: #DC3545;
  }
  
  .profileBlock__mobile li:last-child, .profileBlock__mobile li:last-child svg {
    color: #393017;
  }
  
  /* end - Блок профиля в шапке */

/* Login */

.login__page {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.login__error {
    background: #DC3545;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
}

.login__success {
    background: #27AE60;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
}

.login__success-p {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5rem;
}

.login__textHelp {
    font-size: 14px;
    margin-bottom: 15px;
    color: #393017;
}

.login__form {
    width: 500px;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    padding: 30px;
    margin-top: 30px;
}

.login__form h3 {
    margin-bottom: 20px;
}

.login__form div {
    margin-bottom: 8px;
}

.login__form>button {
    margin-bottom: 8px;
    font-size: 16px;
}

.login__link {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px !important;
}

.login__link a {
    font-size: 16px;
    color: #27AE60;
    text-decoration: none;
    cursor: pointer;
    margin-right: 8px;
}

.login__link a:hover {
    color: #393017;
    text-decoration: underline;
}

.login__social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login__social div {
    width: 300px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    padding: 8px 15px;
    border-radius: 4px;
}

.login__social div svg {
    margin-right: 8px;
}

.login__social div:hover {
    color: #fff;
    background: #393017;
}

.activateEmail {
    display: flex;
    justify-content: center;
}

.activateEmail>div {
    width: 500px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    padding: 30px;
    margin: 60px 0 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
}

.activateEmail>div p {
    margin-bottom: 20px;
}

/* end - Login */

/* Login Header */

.loginHeader__buttons {
    display: flex;
    align-items: center;
}

.loginHeader__buttons a {
    text-decoration: none;
    padding: 5px 12px;
}
  
.loginHeader__buttons a:first-child {
    margin-right: 8px;
}
  
  /* end - Login Header */

/* Page */

.page {
    display: flex;
}

.main {
    width: 100%;
    padding: 20px;
}

/* end - Page */

/* Sidebar */

.sidebar {
    width: 300px;
    border-right: 1px solid #eee;
}

.sidebar ul {
    list-style: none;
    font-size: 16px;
    padding-top: 20px;
}

.sidebar ul .active>a {
    color: #27AE60;
}

.sidebar ul li a {
    display: flex;
    align-items: center;
    color: #393017;
    text-decoration: none;
    padding: 10px 5px;
}

.sidebar ul li a:hover {
    color: #27AE60;

}

.sidebar ul li a svg {
    margin-right: 8px;
}

/* end - Sidebar */

/* Policies */

.documents-menu-ico {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    padding: 1px 20px;
    /* background: #D4EDDA; */
    background: #eee;
    /* border: 1px solid #C3E6CB; */
    /* color: #27AE60; */
    color: #696969;
    border-radius: 4px;
}

.documents-menu-ico div {
    margin-right: 8px;
}

.documents-menu-ico svg {
    display: block;
    width: 24px;
    height: 24px;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
}

.documents-menu-ico svg:hover {
    /* background: #27AE60; */
    /* color: #fff; */
    color: #27AE60;
}

.documents-menu-ico .active svg {
    /* background: #27AE60; */
    /* color: #fff; */
    color: #27AE60;
}

.documents-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.documents-listOne {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32%;
    /* background: #D4EDDA; */
    background: #eee;
    /* border: 1px solid #C3E6CB; */
    border-radius: 4px;
    padding: 5px 10px;
    color: #393017;
    margin-bottom: 12px;
    box-shadow: 0 0 1px rgba(0,0,0,0.1);
}

.documents-listOne-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.documents-listOne-headName {
    font-size: 20px;
    font-weight: bold;
}

.documents-listOne-headName span {
    font-weight: normal;
    font-size: 13px;
}

.documents-listOne-headCompany {
    display: flex;
    /* background: #27AE60; */
    color: #fff;
    font-size: 14px;
    padding: 0 20px;
    align-items: center;
    border-radius: 4px;
    padding: 5px 10px;
}

.documents-listOne-name {
    text-align: end;
    margin-bottom: 20px;
}

.documents-listOne-name p {
    margin-bottom: 0;
    font-size: 13px;
}

.documents-listOne-numberFio {
    font-size: 16px !important;
    font-weight: bold;
}

.documents-listOne-link {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.documents-listOne-link a {
    color: #27AE60;
    text-decoration: none;
}

.documents-listOne-link a:hover {
    color: #155724;
    text-decoration: underline;
}

.documents-listOne-link p {
    display: flex;
    align-items: center;
}

/* end - Documents */

/* Personal Data */

.personal-bg {
    background: #eeeeee;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    padding: 20px;
}

.personal-bg .documents-listOne-headName {
    margin-bottom: 20px;
}

.personal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.personal__list {
    list-style: none;
    padding: 0;
    font-size: 14px;
}

.personal__list li {
    display: flex;
    align-items: center;
    /* margin-bottom: 8px; */
    /* border-bottom: 1px solid #F8F9FA; */
    color: #393017;
    padding: 8px 0;
}

.personal__list li p {
    margin-bottom: 0;
    width: 250px;
}

.personal__list li span {
    font-size: 16px;
    font-weight: bold;
}

.personal__list li span>div {
    width: 250px;
    font-weight: bold;
}

.changePassword {
    display: flex;
    align-items: center;
}

.changePassword p {
    font-size: 24px;
    /* margin-right: 12px; */
    font-weight: bold;
    margin-bottom: 0;
}

.saveButton {
    display: flex;
}

.saveButton button {
    margin-right: 8px;
}

/* end - Personal Data */

/* Cars */

.carsOne {
    background: #eee;
    padding: 20px;
    margin-bottom: 20px;
}

.carsOne .documents-listOne-headName {
    margin-bottom: 10px;
}

.carsOne__list {
    list-style: none;
    padding: 0;
    font-size: 14px;
}

.carsOne__list li {
    display: flex;
    align-items: center;
    /* margin-bottom: 8px; */
    /* border-bottom: 1px solid #F8F9FA; */
    color: #393017;
    padding: 4px 0;
}

.carsOne__list li p {
    margin-bottom: 0;
    width: 250px;
}

.carsOne__list li span {
    font-size: 14px;
}

.carsOne__list li span>div {
    width: 250px;
    margin: 0;
}

.carsOne__list li span>div input:disabled, .carsOne__list li span>div div:disabled {
    cursor: not-allowed !important;
}

/* .css-9e5uuu-MuiPaper-root-MuiAutocomplete-paper {
    width: 500px !important;
} */
/* .MuiAutocomplete-popper {
    width: 500px !important;
} */

.carsOne__list-number {
    font-size: 20px !important;
    font-weight: bold;
}

.carsOne__edit-wrap {
    display: flex;
    justify-content: flex-start;
}

.carsOne__edit-wrap>div {
    margin-right: 8px;
}

.carsOne__edit {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #27AE60;
    cursor: pointer;
    font-weight: bold;
}

.carsOne__edit svg {
    margin-right: 8px;
}

.carsOne__edit p {
    margin-bottom: 0;
}

.carsOne__edit:hover {
    text-decoration: underline;
}

.carsOne__delete {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #DC3545;
    cursor: pointer;
    font-weight: bold;
}

.carsOne__delete svg {
    margin-right: 8px;
}

.carsOne__delete p {
    margin-bottom: 0;
}

.carsOne__delete:hover {
    text-decoration: underline;
}

.carsOne__save {
    display: flex;
}

.carsOne__save button {
    margin-right: 8px;
}

.carsOne__save button:disabled {
    color: #ccc;
    background: #dedede;
    cursor: not-allowed;
}

.noCar__block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
}

/* end - Cars */

/* Referal */

.referal__count {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.referal__countOne {
    width: 49%;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.referal__countOne-header {
    font-size: 16px;
    margin-bottom: 15px;
}

.referal__countOne-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.referal__countOne-digit {
    font-size: 36px;
    color: #27AE60;
    font-weight: bold;
}

.referal__link {
    background: #27AE60;
    padding: 20px;
    color: #fff;
    border-radius: 4px;
    margin-bottom: 20px;
}

.referal__link h3 {
    margin-bottom: 20px;
}

.referal__link-text {
    display: flex;
    align-items: center;
    padding: 6px 20px;
    background: #fff;
    color: #393017;
    font-size: 24px;
    font-weight: bold;
    border-radius: 4px;
}

.referal__link-text p {
    margin-right: 20px;
}

.referal__howwork h3 {
    margin-bottom: 20px;
}

.referal__howwork ol {
    font-size: 14px;
}

.referal__howwork ol li {
    margin-bottom: 8px;
}

/* end - Referal */

/* Documents */

.documentDeleteBtn {
    color: #DC3545 !important;
}

.documentOneFull {
    color: #696969 !important;
}

.documentOneFull p {
    padding-left: 15px;
}

.documentForm {

}

.documentForm__edit {
    margin: 30px 0;
}

.documentForm .documents-listOne-headName {
    margin-bottom: 20px;
}

.documentForm__edit .documents-listOne-headName {
    margin-bottom: 5px !important;
}

.documentForm__center {
    display: flex;
    justify-content: space-between;
}

.documentForm__list {
    list-style: none;
    padding: 0;
    font-size: 14px;
}

.documentForm__list li {
    display: flex;
    align-items: center;
    /* margin-bottom: 8px; */
    /* border-bottom: 1px solid #F8F9FA; */
    color: #393017;
    padding: 4px 0;
}

.documentForm__list li p {
    margin-bottom: 0;
    width: 200px;
}

.documentForm__list li span {
    font-size: 14px;
}

.documentForm__list li span>div, .documentForm__list li span>div .MuiFormControl-root {
    width: 200px;
    margin: 0;
}

.documentForm__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.documentForm__buttons-form button {
    margin-right: 8px;
    margin-top: 10px;
}

.documentForm__buttons-form button:disabled {
    color: #ccc;
    background: #dedede;
    cursor: not-allowed;
}

/* end - Documents */

/* Person */

.personOne {
    background: #eee;
    padding: 20px;
    margin-bottom: 20px;
}

.personOne .documents-listOne-headName {
    margin-bottom: 20px;
}

.personOne__center {
    display: flex;
    justify-content: space-between;
}

.personOne__list {
    list-style: none;
    padding: 0;
    font-size: 14px;
}

.personOne__list li {
    display: flex;
    align-items: center;
    /* margin-bottom: 8px; */
    /* border-bottom: 1px solid #F8F9FA; */
    color: #393017;
    padding: 4px 0;
}

.personOne__list li p {
    margin-bottom: 0;
    width: 250px;
}

.personOne__list li span {
    font-size: 14px;
}

.personOne__list li span>div, .dpersonOne__list li span>div .MuiFormControl-root {
    width: 200px;
}

.personOne__list-inn {
    font-size: 20px !important;
    font-weight: bold;
}

.personOne__list-number {
    display: flex;
    align-items: center;
}

.personOne__list-number span {
    margin-right: 8px;
}

.personOne__list svg {
    /* margin-right: 8px; */
    margin-left: 8px;
    width: 20px;
    height: 20px;
    color: #27AE60;
}

.personOne__list svg:hover {
    cursor: pointer;
    color: #393017;
}

.personOne__listSecond {
    border-bottom: 1px solid #ccc;
}

.personOne__listSecond li {
    color: #696969;
}

.personOne__listPerson {
    text-align: end;
}

.personOne__listPerson p {
    font-size: 16px;
    margin-bottom: 8px;
}

.personOne__listPerson-name {
    font-size: 20px !important;
    font-weight: bold;
}

.personOne__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.personOne__buttons-form button {
    margin-right: 8px;
}

.personOne__buttons-form button:disabled {
    color: #ccc;
    background: #dedede;
    cursor: not-allowed;
}

.personOne__edit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    color: #27AE60;
    cursor: pointer;
    font-weight: bold;
}

.personOne__edit svg {
    margin-right: 8px;
}

.personOne__edit p {
    margin-bottom: 0;
    width: auto;
}

.personOne__edit:hover {
    text-decoration: underline;
}

.personOne__delete {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    color: #DC3545;
    cursor: pointer;
    font-weight: bold;
}

.personOne__delete svg {
    margin-right: 8px;
}

.personOne__delete p {
    margin-bottom: 0;
    width: auto;
}

.personOne__delete:hover {
    text-decoration: underline;
}

.personOne__icoButtons svg {
    margin-left: 8px;
    width: 32px;
    height: 32px;
}

/* end - Person */

/* Policy */

.pilicies__h3 {
    margin-bottom: 20px;
}

.policy__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.policy__head>div {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    padding: 10px;
}

.policy__head>div>img {
    width: 150px;
}

.policy__status {
    flex-direction: column;
    font-size: 16px;
}

.policy__statusInfo {
    margin-top: 8px;
    font-weight: bold;
}

.policy__statusGreen {
    color: #27AE60;
}

.policy__statusRed {
    color: #DC3545;
}

.policy__statusYellow {
    color: #F5A623;
}

.policy_info {
    display: flex;
    justify-content: space-between;
    background: #eee;
    padding: 20px;
    font-size: 14px;
}

.policy_info>div {
    width: 49%;
}

.policy_infoLeft {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    padding: 20px;
}

.policy_infoRight>div {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    padding: 20px;
    margin-bottom: 20px;
}

.policy_infoRight>div:last-child {
    margin-bottom: 0;
}

.policy_info ul {
    list-style: none;
    padding: 0;
}

.policy_info ul li {
    display: flex;
    margin-bottom: 8px;
}

.policy_info ul li p {
    width: 230px;
    margin-right: 8px;
}

.policy_info ul li span {
    font-weight: bold;
    width: 100%;
}

.policy_info-more {
    color: #27AE60;
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
    font-size: 16px;
}

.policy_info-more:hover {
    color: #393017;
}

/* end - Policy */

/* Admin Site - ALL */

.admin__panelAdd {
    display: flex;
    margin-bottom: 20px;
}

.admin__panelAdd svg {
    margin-right: 8px;
    font-size: 32px;
    cursor: pointer;
}

.admin__panelAdd svg:hover {
    color: #27AE60;
}

/* end - Admin Site - ALL */

/* Admin Site - Menu */

.adminMenu__addForm {
    margin-bottom: 30px;
}

.adminMenu__addForm-inputs {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-end;
}

.adminMenu__addForm-inputs svg {
    cursor: pointer;
}

.adminMenu__addForm-inputs svg:hover {
    color: #DC3545;
}

.adminMenu__addForm-input {
    margin-right: 15px !important;
}

.adminMenu__list-add button {
    margin-right: 8px;
}

.adminMenu__list {
    margin-bottom: 20px;
}

.adminMenu__one {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    padding: 20px;
    font-size: 14px;
    margin-bottom: 20px;
}

.adminMenu__one-header {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 20px;
}

.adminMenu__one-header p {
    margin-right: 8px;
}

.adminMenu__item {
    font-size: 14px;
}

/* end - Admin Site - Menu */

/* Admin Home Products */

.adminProductsHomeImg__block {

}

.adminProductsHomeImg__blockOne {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    border-radius: 4px;
    margin-bottom: 20px;
}

.adminProductsHomeImg__blockOne-name {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
}

.adminProductsHomeImg__blockOne-name p {
    margin-right: 15px;
}

.adminProductsHomeImg__blockOne-description {
    font-size: 14px;
    margin-bottom: 20px;
}

.adminProductsHomeImg__blockOne-description p {
    margin-bottom: 8px;
}

.adminProductsHomeImg__blockOne-options {
    display: flex;
    font-size: 16px;
}

.adminProductsHomeImg__blockOne-options p {
    margin-right: 15px;
}

.adminProductsHomeImg__blockOne-options p span {
    font-weight: bold;
    color: #27AE60;
}

.adminProductsHomeSimple__blockOne-description {
    display: flex;
    margin-bottom: 20px;
    font-size: 16px;
}

.adminProductsHomeSimple__blockOne-description p {
    margin-right: 15px;
}

/* end - Admin Home Products */

/* Admin Questions */

.adminQuestions__block {

}

.adminQuestions__block-one {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    font-size: 14px;
}

.adminQuestions__block-one>div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.adminQuestions__block-one p {
    margin-bottom: 8px;
}

.adminQuestions__block-one span {
    color: #27AE60;
    font-weight: bold;
}

.adminQuestions__block-one .MuiInput-root {
    margin-bottom: 8px;
}

.adminQuestions__block-one-buttons {
    flex-direction: row !important;
}

.adminQuestions__block-one-buttons button {
    margin-right: 8px;
}

/* end - Admin Questions */

/* Нет элементов */

.noElements__block {
    margin: 10px 0;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
}

/* end - Нет элементов */

/* Admin Post */

.adminPost__view {
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    font-size: 14px;
}

.adminPost__view h3 {
    margin-bottom: 30px;
}

.adminPost__view-icons {
    margin-bottom: 30px;
}

.adminPost__view-options {
    display: flex;
    align-items: center;
    background: #393017;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.adminPost__view-options p {
    margin-right: 15px;
}

.adminPost__view-name, 
.adminPost__view-title, 
.adminPost__view-description, 
.adminPost__view-city>div {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.adminPost__view-name p {
    margin-right: 15px;
    font-weight: bold;
}

.adminPost__view-name p span, 
.adminPost__view-title p span, 
.adminPost__view-description p span, 
.adminPost__view-city p span {
    color: #27AE60;
    font-weight: bold;
}

.adminPost__view-title p, 
.adminPost__view-description p, 
.adminPost__view-city p {
    margin-right: 15px;
}

.adminPost__view-text>p {
    color: #27AE60;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.adminPost__view-text>div {
    max-height: 400px;
    overflow: hidden;
    box-shadow: 0 0 3px rgba(0,0,0,0.4);
    padding: 20px;
    margin-bottom: 30px;
    overflow: scroll;
}

/* end - Admin Post */

/* Admin Post Form */

.adminPost__form {
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
}

.adminPost__form h2 {
    margin-bottom: 30px;
}

.adminPost__form-colums {
    display: flex;
    justify-content: space-between;
}

.adminPost__form-left>div {
    margin-bottom: 30px;
}

.adminPost__form-right {
    background: #27AE60;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    color: #fff;
    border: 3px solid #393017;
}

.adminPost__form-right .MuiInputLabel-root {
    color: #fff;
}

.adminPost__form-right .MuiSelect-select, .adminPost__form-right .MuiInput-input {
    color: #fff;
}

.adminPost__form-visible {
    font-size: 16px;
    color: #393017;
    font-weight: bold;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    margin: 15px 0 !important;
}

.adminPost__form-visible label {
    cursor: pointer;
    margin-right: 8px;
}

.adminPost__form-cityCountry {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #393017;
    font-weight: bold;
    margin-bottom: 20px;
}

.adminPost__form-cityCountry label {
    margin-bottom: 4px;
}

.adminPost__form-tabs {
    margin: 50px 0 20px 0;
}

.adminPost__form-tabs button {
    color: #393017;
}

.adminPost__form-tabs .Mui-selected {
    color: #27AE60 !important;
}

.rdw-editor-toolbar {
    
}

.rdw-editor-main {
    /* background: #eee; */
    padding: 0 10px;
    margin-bottom: 30px;
    box-shadow: 0 0 3px rgba(0,0,0,0.4);
    max-height: 400px;
}

/* end - Admin Post Form */

/* Admin Company Form */

.adminCompany__form {
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
}

.adminCompany__form h2 {
    margin-bottom: 40px;
}

.adminCompany__form form>div {
    margin-bottom: 20px;
}

.adminCompany__form-visible {
    width: 200px;
    display: flex !important;
    justify-content: center;
    border-radius: 4px;
    background: #393017;
    color: #27AE60;
    padding: 10px !important;
    flex-direction: row !important;
    margin-bottom: 20px;
}

.adminCompany__form-visible label {
    margin-right: 15px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}

.adminCompany__form-flex {
    display: flex;
    justify-content: space-between;
}

.adminCompany__form-flex>div {
    width: 100%;
    margin: 0 10px;
}

/* end - Admin Company Form */

/* Admin Category Form */

.adminCategory__form {
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
}

.adminCategory__form-name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.adminCategory__form-name>div {
    width: 30%;
}

.adminCategory__form-title {
    display: flex;
    flex-direction: column;
}

.adminCategory__form-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.adminCategory__form-buttons button {
    margin-right: 15px;
}

.adminCategory {
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    border-radius: 4px;
}

.adminCategory p {
    margin-right: 25px;
}

.adminCategory p:last-child {
    margin-right: 0;
}

.adminCategory span {
    color: #27AE60;
    font-weight: bold;
}

.adminCategory__name {
    display: flex;
    font-size: 16px;
    margin-bottom: 20px;
}

.adminCategory__titleAndDescr {
    display: flex;
    font-size: 16px;
    margin-bottom: 20px;
}

/* end - Admin Category Form */

/* Admin Insurance Companies */

.adminInsCompany__view {
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
}

.adminInsCompany__view h2 {
    margin-bottom: 40px;
}

.adminInsCompany__view h3, .adminInsCompany__view h4, .adminInsCompany__view>div {
    margin-bottom: 20px;
}

.adminInsCompany__view-flex {
    display: flex;
}

.adminInsCompany__view-flex p {
    margin-right: 15px;
    font-size: 14px;
}

.adminInsCompany__view-flex p span {
    color: #27AE60;
    font-weight: bold;
}

/* end - Admin Insurance Companies */

/* Admin - Green Card */

.adminGcPriceTable {

}

.adminGcPriceTable div {
    display: flex;
    justify-content: space-around;
    margin: 5px 1px;
    align-items: center;
}

.gcCalc__buttons {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.gcCalc__buttons svg {
    margin-right: 15px;
    cursor: pointer;
}

.gcCalc__buttons>div {
    margin-right: 15px;
}

.gcCalc__periodChoise span {
    position: relative;
    background: #393017;
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
}

.gcCalc__periodChoise-block {
    display: none;
    position: absolute;
    padding: 10px;
    font-size: 14px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    z-index: 99;
    font-weight: bold;
    margin-top: 5px;
    border-radius: 4px;
    max-height: 200px;
    /* height: 30px; */
    overflow-y: scroll;
}

.gcCalc__periodChoise-visible {
    display: block;
}

.gcCalc__periodChoise-block>div {
    cursor: pointer;
    color: #393017;
}

.gcCalc__periodChoise-block>div:hover {
    color: #27AE60;
}

.gcCalc__dates {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
}

.gcCalc__dates div {
    margin-right: 15px;
}

/* end - Admin - Green Card */

/*
** Footer **
*/

.footer {
    background: #E2E2E1;
    -webkit-box-shadow: inset 0px 4px 0px #6C757D;
    -moz-box-shadow: inset 0px 4px 0px #6C757D;
    box-shadow: inset 0px 4px 0px #6C757D;
  }
  
  .footer__wrap {
    -webkit-display: flex;
  -moz-display: flex;
  display: flex;
    -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
    padding: 24px 10px;
  }
  
  .footer__menu {
    -webkit-display: flex;
  -moz-display: flex;
  display: flex;
    padding-top: 48px;
  }
  
  .footer__menu ul {
    list-style: none;
    padding-left: 0;
    font-size: 14px;
    line-height: 19px;
  }
  
  .footer__menu ul a {
    color: #6C757D;
    text-decoration: none;
  }
  
  .footer__menu ul a:hover {
    text-decoration: underline;
  }
  
  .footer__menu-header {
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  
  .footer__menu-left {
    margin-right: 150px;
  }
  
  .footer__menu-leftOne {
    margin-bottom: 20px;
  }
  
  .footer__menu-rightOne-payments {
    -webkit-display: flex;
  -moz-display: flex;
  display: flex;
  }
  
  .footer__menu-rightOne-payments img {
    margin-right: 12px;
  }
  
  .footer__info-logo {
    margin-bottom: 20px;
  }
  
  .footer__info-contacts {
    color: #6C757D;
    font-size: 14px;
    line-height: 19px;
    padding-left: 45px;
    margin-bottom: 36px;
  }
  
  .footer__info-contacts a {
    color: #6C757D;
    font-size: 14px;
    line-height: 19px;
    text-decoration: none;
  }
  
  .footer__info-contacts a:hover {
    text-decoration: underline;
  }
  
  .footer__info-contacts p {
    -webkit-display: flex;
  -moz-display: flex;
  display: flex;
    -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin-bottom: 1rem;
  }
  
  .footer__info-contacts p img {
    margin-right: 8px;
  }
  
  .footer__info-messengers {
    -webkit-display: flex;
  -moz-display: flex;
  display: flex;
    padding-left: 40px;
  }
  
  .footer__info-messengers a {
    display: block;
    margin-right: 12px;
  }
  
  .footer__copyright {
    color: #6C757D;
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1rem;
  }
  
  /*
  ** end - Footer **
  */

  @media only screen and (max-width: 1200px) {
    .personOne__list li span {
        width: 300px;
    }
  }

  @media only screen and (max-width: 1075px) {
    .documents-listOne {
        width: 49%;
    }
  }

  @media only screen and (max-width: 1020px) {
    .policy_infoRight ul li {
        flex-direction: column;
    }
  }

  @media only screen and (max-width: 992px) {

    .personOne__list li p {
        width: 190px;
    }
    
    /* Footer */
    .footer__wrap {
        padding: 24px 20px;
    }
    /* end - Footer */
  
  }

  @media only screen and (max-width: 950px) {

    .carsOne__list li span {
        max-width: 300px;
    }

    .personOne__list li span {
        width: 200px;
    }

    .policy_info {
        flex-direction: column;
    }

    .policy_info>div {
        width: 100%;
    }

    .policy_infoLeft {
        margin-bottom: 20px;
    }

    .policy_infoRight ul li {
        flex-direction: row;
    }

    /* Footer */
    .footer__menu-left {
        margin-right: 70px;
    }
    /* end - Footer */
  
  }

  @media only screen and (max-width: 850px) {
    .carsOne__list li span {
        max-width: 200px;
    }

    .policy__head>div {
        width: 32%;
    }

    .policy__head>div>img {
        width: 70px;
    }
    
    /* Footer */
    .footer__info-messengers {
        -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
    }
    /* end - Footer */
  }

  @media only screen and (max-width: 800px) {
    .documents-listOne {
        width: 100%;
    }

    .personOne__center {
        flex-direction: column-reverse;
    }

    .carsOne__list-form li p {
        width: 200px;
    }
  }

  @media only screen and (max-width: 767px) {

    .header__nav-desktop {
        display: none;
    }

    .header__nav-mobile {
        -webkit-display: flex;
        -moz-display: flex;
        display: flex;
        align-items: center;
    }
  
    .header__nav-mobile ul:first-child {
        margin-right: 8px;
    }
  
    .header__nav-mobile .isurance__nav-ul {
        position: absolute !important;
        left: -111px;
    }
  
    .header__nav-mobile .isurance__nav-ul .dropdown-toggle {
        margin-bottom: 4px;
    }
  
    .header__nav-mobile #header__nav-mobile-more {
        background: #27AE60;
    }
  
    .header__nav-mobile #header__nav-mobile-more::after {
        display: none;
    }

    .carsOne__list li span {
        max-width: 150px;
    }

    /* Footer */
    .footer__wrap {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        flex-wrap: wrap;
    }
  }

  @media only screen and (max-width: 750px) {
    .carsOne__list-form li span>div {
        width: 200px;
    }

    .personal__list li p {
        width: 160px;
    }

    .personal__list li span>div {
        width: 200px;
    }
  }

  @media only screen and (max-width: 700px) {
    .carsOne__list li p {
        width: 200px;
    }
  }

  @media only screen and (max-width: 680px) {
    .personOne__list li p {
        width: 165px;
    }

    .carsOne__list-form li {
        flex-direction: column;
        align-items: start;
    }

    .carsOne__list-form li span>div {
        width: 270px;
    }

    .documentForm__list li p {
        width: 140px;
    }
    
    .policy__head {
        flex-direction: column;
    }

    .policy__head>div {
        width: 100%;
        margin-bottom: 8px;
    }
  }

  @media only screen and (max-width: 660px) {
    .profileBlock ul {
      margin-right: 0 !important;
    }
    .profileBlock ul li {
      min-height: auto;
    }
  }

  @media only screen and (max-width: 650px) {
    .personOne__list li {
        flex-direction: column;
        align-items: start;
    }

    .policy_info ul li {
        flex-direction: column;
    }

    .personal__list li {
        flex-direction: column;
        align-items: start;
    }
  }

  @media only screen and (max-width: 630px) {
    .carsOne__list li {
        flex-direction: column;
        align-items: start;
    }

    .documentForm__list li {
        flex-direction: column;
        align-items: start;
    }
  }

  @media only screen and (max-width: 575px) {
  
    /* Footer */
    .footer__menu-left {
        margin-right: 20px;
    }
    /* end - Footer */
  }

  @media only screen and (max-width: 550px) {

    .sidebar {
        width: 100%;
        border-right: 0;
        border-bottom: 1px solid #eee;
    }

    .page {
        flex-direction: column;
    }

    .carsOne__list li {
        flex-direction: row;
        /* align-items: start; */
    }

    .personOne__list li {
        flex-direction: row;
        /* align-items: start; */
    }

    .carsOne__list-form li span>div {
        width: 200px;
    }

    .policy_info ul li {
        flex-direction: row;
    }

    .personal__list li {
        flex-direction: row;
        /* align-items: start; */
    }
  }

  @media only screen and (max-width: 500px) {
    .carsOne__list-form li {
        flex-direction: column;
        align-items: start;
    }

    .carsOne__list-form li span>div {
        width: 300px;
    }
  }

  @media only screen and (max-width: 450px) {

    .personal__list li span>div {
        width: 170px;
    }
  
    /* Footer */
    .footer__menu {
        -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
    }
  
    .footer__menu-rightOne-payments {
        -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
    }
    /* end - Footer */
  }

  @media only screen and (max-width: 420px) {
    .carsOne__list li {
        /* flex-direction: column;
        align-items: start; */
    }

    .carsOne__list li p {
        width: 150px;
        margin-right: 8px;
    }

    .personOne__list li {
        flex-direction: column;
        align-items: start;
    }

    .personal__list li {
        flex-direction: column;
        align-items: start;
    }
  }

  @media only screen and (max-width: 400px) {
    .carsOne__list li {
        flex-direction: column;
        align-items: start;
    }

    .carsOne__list li p {
        width: 200px;
        margin-right: 0;
    }

    .carsOne__list-form li span>div {
        width: 200px;
    }
  }

  @media only screen and (max-width: 370px) {
    .policy_info ul li {
        flex-direction: column;
    }
  }