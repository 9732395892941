.myBtn {
    background: #27AE60;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    padding: 7px 12px;
    border: none;
}

.myBtn svg {
    margin-right: 8px;
}

.myBtn:hover {
    background: #393017;
    color: #fff;
}